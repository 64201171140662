<template>
  <div class="tag-item"
  :class="{
    'active': isActive
  }"
  @click="$emit('onTagClick', tag)"
  >{{name}}</div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    tag: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.tag-item {
  cursor: pointer;
  color: black;
  font-size: 1.2rem;
  border-radius: 8px;
  border: 1px solid $grey97;
  background: white;
  padding: 4px 12px;
  margin-top: 8px;
  margin-right: 8px;

  &.active {
    color: white;
    background: $primary;
    border: 1px solid $primary;
  }
}
</style>
