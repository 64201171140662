<template>
  <div class="tag-list">
    <TagItem
      v-for="(tag, index) in tags"
      :key="index"
      :name="tag.name || ''"
      :tag="tag"
      :isActive="isActive(tag)"
      @onTagClick="(tag) => $emit('onTagsChanged', tag) "
    />
  </div>
</template>

<script>
import TagItem from '@/components/TagItem.vue'
export default {
  components: {
    TagItem
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    selectedTags: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isActive (tag) {
      const filterTags = this.selectedTags.filter((t) => t.id === tag.id)
      return filterTags.length > 0
    }
  }
}
</script>
<style lang="scss" scoped>
.tag-list {
  display: flex;
  flex-wrap: wrap;
}
</style>
