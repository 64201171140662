<template>
  <div>
    <button class="btn" :class="[
      type,
      {
        'full': isFull
      }
    ]" @click="handleClick">
      <span><slot>Button</slot></span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    },
    onClick: {
      type: Function
    },
    isFull: {
      type: Boolean
    }
  },
  methods: {
    handleClick () {
      if (this.onClick) {
        this.onClick()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.btn {
  outline: none;
  touch-action: manipulation;
  border-radius: 4px;
  min-height: 42px;
  min-width: 205px;
  box-shadow: none;
  -webkit-appearance: none;
  font-size: 1.8rem;
  font-weight: 400;
  border: solid 1px #b8d0c2;
  color: white;
  background-color: #b8d0c2;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  cursor: pointer;
  &.error {
    color: $error;
    background-color: white;
    border: 1px solid $error;
  }
  &.success {
    color: white;
    background-color: $secondary;
    border: none;
  }
  &.outline {
    color: $btn-outline;
    background-color: transparent;
    padding: 0;
    text-align: center;
    border: 1px solid $border;
  }
  &.full {
    width: 100%;
  }
  &:active {
    border: solid 1px $secondary;
    color: white;
    background-color: $secondary;
  }
}
</style>
