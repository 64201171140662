<template>
  <div class="claimed-coupon-modal max-width-page">
    <modal is-close class="has-margin-top-20"
    has-padding
    >
      <div class="is-flex justify-center align-items-center">
        <img src="@/assets/icon/check-circle.svg"/>
      </div>
      <div class="has-text-center
      _fs-30
      has-text-primary
      has-text-centered
      has-text-weight-medium
      has-margin-top-20">
        {{$t('claimModal.success')}}</div>
      <div class="_fs-16 has-text-black has-text-centered has-margin-top-10">{{$t('claimModal.successDetail')}}</div>
      <!-- <div class="has-margin-top-10 has-text-black40 has-text-centered _fs-14 has-text-weight-medium">
        {{$t('common.couponId', {couponId})}}
      </div> -->
      <div class="has-margin-top-60 is-flex justify-center">
        <RoundButton @click="$router.replace({ name: 'MyCoupon' })">
          {{$t('claimModal.btnMyCoupon')}}
        </RoundButton>
      </div>
      <div class="has-margin-top-10 is-flex justify-center">
        <RoundButton type="text" @click="$router.replace({ name: 'Home' })">
          {{$t('claimModal.btnBackToHome')}}
        </RoundButton>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/base/Modal.vue'
import RoundButton from '@/components/base/RoundButton.vue'
export default {
  props: {
    couponId: {
      type: String,
      required: true
    },
    error: {
      type: String
    }
  },
  components: {
    Modal,
    RoundButton
  },
  methods: {
    close () {
      this.$parent.close()
      this.$emit('input', '')
      this.$emit('onClose')
    },
    onInputChange (newValue) {
      this.$emit('input', newValue)
    },
    submit () {
      this.resetScroll()
      this.$emit('submit')
    },
    resetScroll () {
      const y = document.documentElement.scrollTop
      window.scrollTo(0, y - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.claimed-used-modal {
}
</style>
