<template>
  <div class="about">
    <div>
      <Navbar></Navbar>
    </div>
    <div class="has-margin-top-10">
      <Backbar></Backbar>
    </div>
    <div  class="has-margin-top-10">
      <RInput/>
    </div>
    <div  class="has-margin-top-10">
      <ChangeLanguage/>
    </div>
    <div  class="has-margin-top-10">
      <Input/>
    </div>
    <div  class="has-margin-top-10">
      <DropdownDatePicker/>
    </div>
    <div  class="has-margin-top-10">
      <RoundButton/>
    </div>
    <div  class="has-margin-top-10">
      <Button/>
    </div>
     <div class="has-margin-top-10">
      <SelectProvince/>
    </div>
    <div class="has-margin-top-10">
      <TagList />
    </div>
    <div  class="has-margin-top-10">
      <Button @click.native="isConditionModalActive = !isConditionModalActive">A</Button>
    </div>
    <div  class="has-margin-top-10">
      <Button @click.native="isAdminModalActive = !isAdminModalActive">B</Button>
    </div>
    <div  class="has-margin-top-10">
      <Button @click.native="isClaimedCouponModalActive = !isClaimedCouponModalActive">C</Button>
    </div>
    <div  class="has-margin-top-10">
      <Button @click.native="isCouponUsedModalActive = !isCouponUsedModalActive">D</Button>
    </div>
    <div  class="has-margin-top-10">
      <Button @click.native="isMemberCardModalActive = !isMemberCardModalActive">E</Button>
    </div>
    <div  class="has-margin-top-10">
      <Button @click.native="isMenuModalActive = !isMenuModalActive">F</Button>
    </div>
    <div  class="has-margin-top-10">
      <Button @click.native="isSuccessModalActive = !isSuccessModalActive">G</Button>
    </div>
    <div  class="has-margin-top-10">
      <Button @click.native="isRenewMemberModalActive = !isRenewMemberModalActive">H</Button>
    </div>
    <b-modal
      scroll="clip"
      class="justify-start"
      :active.sync="isConditionModalActive"
      :can-cancel="false"
    >
      <ConditionModal/>
    </b-modal>
    <b-modal
      scroll="clip"
      class="justify-center"
      :active.sync="isAdminModalActive"
      :can-cancel="false"
    >
      <AdminModal
      />
    </b-modal>
    <b-modal
      scroll="clip"
      class="justify-center"
      :active.sync="isClaimedCouponModalActive"
      :can-cancel="false"
    >
      <ClaimedCouponModal
      />
    </b-modal>
    <b-modal
      scroll="clip"
      class="justify-center"
      :active.sync="isSuccessModalActive"
      :can-cancel="false"
    >
      <SuccessModal
        title="Saved"
        description="You have successfully editing your profile."
        submitText="Update to hospital"
        cancelText="back to home"
      />
    </b-modal>
    <b-modal
      scroll="clip"
      class="justify-start"
      :active.sync="isCouponUsedModalActive"
      :can-cancel="false"
    >
      <CouponUsedModal
      />
    </b-modal>
    <b-modal
      scroll="clip"
      class="justify-start"
      :active.sync="isMemberCardModalActive"
      :can-cancel="false"
    >
      <MemberCardModal
      />
    </b-modal>
    <b-modal
      scroll="clip"
      class="justify-start"
      :active.sync="isMenuModalActive"
      :can-cancel="false"
    >
      <MenuModal
      />
    </b-modal>
    <b-modal
      scroll="clip"
      class="justify-center"
      :active.sync="isRenewMemberModalActive"
      :can-cancel="false"
    >
      <RenewMemberModal
      />
    </b-modal>
    <ExpiredMemberCard />
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Backbar from '@/components/Backbar.vue'
import Input from '@/components/base/Input.vue'
import RInput from '@/components/base/RInput.vue'
import DropdownDatePicker from '@/components/base/DropdownDatePicker.vue'
import RoundButton from '@/components/base/RoundButton.vue'
import Button from '@/components/base/Button.vue'
import SelectProvince from '@/components/base/SelectProvince.vue'
import TagList from '@/components/TagList.vue'

import AdminModal from '@/components/modal/AdminModal.vue'
import ClaimedCouponModal from '@/components/modal/ClaimedCouponModal.vue'
import ConditionModal from '@/components/modal/ConditionModal.vue'
import CouponUsedModal from '@/components/modal/CouponUsedModal.vue'
import MemberCardModal from '@/components/modal/MemberCardModal.vue'
import SuccessModal from '@/components/modal/SuccessModal.vue'
import MenuModal from '@/components/modal/MenuModal.vue'
import RenewMemberModal from '@/components/modal/RenewMemberModal.vue'

import ChangeLanguage from '@/components/ChangeLanguage.vue'

import ExpiredMemberCard from '@/components/ExpiredMemberCard.vue'
export default {
  components: {
    Navbar,
    Backbar,
    Input,
    RInput,
    DropdownDatePicker,
    RoundButton,
    Button,
    SelectProvince,
    TagList,

    AdminModal,
    ClaimedCouponModal,
    ConditionModal,
    CouponUsedModal,
    MemberCardModal,
    MenuModal,
    SuccessModal,
    RenewMemberModal,
    ChangeLanguage,
    ExpiredMemberCard
  },

  data () {
    return {
      isConditionModalActive: false,
      isMemberCardModalActive: false,
      isClaimedCouponModalActive: false,
      isCouponUsedModalActive: false,
      isMenuModalActive: false,
      isAdminModalActive: false,
      isSuccessModalActive: false,
      isRenewMemberModalActive: false
    }
  }
}
</script>
