<template>
  <div class="success-modal max-width-page">
    <modal is-close class="has-margin-top-20"
    has-padding
    >
      <div class="is-flex justify-center align-items-center" @click="close()">
        <img src="@/assets/icon/check-circle.svg"/>
      </div>
      <div class="has-text-center
      _fs-30
      has-text-primary
      has-text-centered
      has-text-weight-medium
      has-margin-top-20">
        {{title}}</div>
      <div class="_fs-16 has-text-black has-text-centered has-margin-top-10">{{description}}</div>
      <div class="has-margin-top-60 is-flex justify-center">
        <RoundButton @click="$emit('onSumbit')">
          {{submitText}}
        </RoundButton>
      </div>
      <div class="has-margin-top-10 is-flex justify-center">
        <RoundButton type="text" @click="$emit('onCancel')">
          {{cancelText}}
        </RoundButton>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/base/Modal.vue'
import RoundButton from '@/components/base/RoundButton.vue'
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    submitText: {
      type: String,
      required: true
    },
    cancelText: {
      type: String,
      required: true
    }
  },
  components: {
    Modal,
    RoundButton
  },
  methods: {
    close () {
      this.$parent.close()
      this.$emit('onClose')
    },
    submit () {
      this.resetScroll()
      this.$emit('onSumbit')
    },
    resetScroll () {
      const y = document.documentElement.scrollTop
      window.scrollTo(0, y - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.success-modal {
}
</style>
